import { default as users6CxIgzekyUMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/admin/users.vue?macro=true";
import { default as applicationP0DemVKq4bMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/application.vue?macro=true";
import { default as attachmentspzgEsXaw6PMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/attachments.vue?macro=true";
import { default as contactuRkf3YK5L2Meta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/contact.vue?macro=true";
import { default as prescreenAm6wMZieVCMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/prescreen.vue?macro=true";
import { default as scholarshiptqhB7bqoVEMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/scholarship.vue?macro=true";
import { default as summaryQ5HTAHB5HKMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/summary.vue?macro=true";
import { default as caseVrLAif0RL0Meta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case.vue?macro=true";
import { default as case_45assignmentspqAhDJecL2Meta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/csp-admin/case-assignments.vue?macro=true";
import { default as index0tHZ2CmvHjMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/index.vue?macro=true";
import { default as new_45caseO3eIbjtKxuMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/new-case.vue?macro=true";
import { default as providerlistbUKv6fVaeFMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/providerlist.vue?macro=true";
import { default as eligibilityFHJPnzW81sMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/public/csp/eligibility.vue?macro=true";
import { default as _91id_93QIa0j21nGHMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/report/[id].vue?macro=true";
import { default as search8LDU5FVHluMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/search.vue?macro=true";
import { default as indexNlT2oH1fbiMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/settings/index.vue?macro=true";
import { default as notifications8fm8kNuDesMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/settings/notifications.vue?macro=true";
import { default as settingscwUXGLqxEXMeta } from "/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/settings.vue?macro=true";
export default [
  {
    name: "admin-users",
    path: "/admin/users",
    meta: users6CxIgzekyUMeta || {},
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "case",
    path: "/case",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case.vue").then(m => m.default || m),
    children: [
  {
    name: "case-id-application",
    path: ":id()/application",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/application.vue").then(m => m.default || m)
  },
  {
    name: "case-id-attachments",
    path: ":id()/attachments",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/attachments.vue").then(m => m.default || m)
  },
  {
    name: "case-id-contact",
    path: ":id()/contact",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/contact.vue").then(m => m.default || m)
  },
  {
    name: "case-id-prescreen",
    path: ":id()/prescreen",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/prescreen.vue").then(m => m.default || m)
  },
  {
    name: "case-id-scholarship",
    path: ":id()/scholarship",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/scholarship.vue").then(m => m.default || m)
  },
  {
    name: "case-id-summary",
    path: ":id()/summary",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/case/[id]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "csp-admin-case-assignments",
    path: "/csp-admin/case-assignments",
    meta: case_45assignmentspqAhDJecL2Meta || {},
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/csp-admin/case-assignments.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "new-case",
    path: "/new-case",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/new-case.vue").then(m => m.default || m)
  },
  {
    name: "providerlist",
    path: "/providerlist",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/providerlist.vue").then(m => m.default || m)
  },
  {
    name: "public-csp-eligibility",
    path: "/apply/childcare/eligibility",
    meta: eligibilityFHJPnzW81sMeta || {},
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/public/csp/eligibility.vue").then(m => m.default || m)
  },
  {
    name: "report-id",
    path: "/report/:id()",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/search.vue").then(m => m.default || m)
  },
  {
    name: settingscwUXGLqxEXMeta?.name,
    path: "/settings",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/codebuild/output/src421377640/src/WDI.Portal/wdi.portal.client/pages/settings/notifications.vue").then(m => m.default || m)
  }
]
  }
]